import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from"@mui/styles";
import { Button } from "@mui/material";
import { GlobalContext } from "../../../../components/context/GlobalContext";
import Checkout from "../../../../components/stripe/Checkout";
import dynamic from "next/dynamic";
import api from "../../../../components/settings/api";

const UpIcon = dynamic(
  () => import("../../../../components/common/nav/Token/UpIcon"),
  {
    ssr: false,
  }
);

const MinusButton = dynamic(() => import("./minusButtonIcon"), {
  ssr: false,
});

const PlusButton = dynamic(() => import("./plusButtonIcon"), {
  ssr: false,
});

const PlusButtonDisabled = dynamic(() => import("./plusButtonDisabled"), {
  ssr: false,
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 20003, // Higher z-index to appear above other elements
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
  text: {
    margin: "1rem",
  },
  store_background: {
    position: "absolute",
  },
  ticketStub: {
    position: "absolute",
    top: "20%",
  },
  counter: {
    top: 35,
    left: "24.5%",
    display: "flex",
    position: "absolute",
    direction: "row",
  },
  buttonSub: {
    left: -30,
    top: 25,
  },
  buttonAdd: {
    right: -50,
    top: 25,
  },
  ticketBoothCounter: {
    display: "inline-flex",
    position: "absoulte",
    width: "60%",
  },
  buttonCount: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
  },
  margin: {
    margin: "51px 28px",
  },
  textField: {
    width: "2ch",
  },
  root: {
    "& > *": {
      width: "35ch",
      maxWidth: "100%",
      position: "absolute",
      top: -30,
      left: 0,
    },
  },
}));

export default function TokenMenu() {
  const appState = useContext(GlobalContext);
  const {
    isTokenOpen,
    toggleTokenMenu,
    hideTokenMenu,
    navTeamCredits,
    setNavTeamCredits,
  } = appState;

  const wrapperRef = useRef(null);
  const classes = useStyles();
  const [input, setInput] = useState("");
  const [tokens, setTokens] = useState(0);
  const [freeTokens, setFreeTokens] = useState(0);
  const [result, setResult] = useState("");

  const price = 0.5;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
  }, [isTokenOpen]);

  const getCouponDetails = async (code) => {
    try {
      const res = await api.get("/api/v1/coupons/code/" + code);

      if (res.data.result) {
        setResult(res.data.result);
        return;
      }

      setResult("Valid!");
      setTokens(res.data.coupon.number_of_tokens);
      setFreeTokens(res.data.coupon.number_of_tokens);

      return;
    } catch (err) {
      if (err.message === "Request failed with status code 401") {
        console.error(err);
      } else {
        throw err;
      }
    }
  };

  const useToken = async (code) => {
    try {
      const res = await api.post("/api/v1/coupons/code/use-coupon", {
        coupon: code,
      });

      if (res.status === 204) {
        return setResult("Not Valid");
      }

      setNavTeamCredits(res.data.result);

      setInput("");
      setTokens(0);
      setFreeTokens(0);
      setResult("");

      return hideTokenMenu();
    } catch (err) {
      if (err.message === "Request failed with status code 401") {
        console.error(err);
      } else {
        throw err;
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      isTokenOpen
    ) {
      hideTokenMenu();
    }
  };

  return (
    <div
      ref={wrapperRef}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      role="button"
      tabIndex={0}
      className={`token--token_menu ${isTokenOpen ? "show" : "hide"}`}
    >
      <div className="token--discount_container">
        <input
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            setResult("");
          }}
          placeholder="Enter Promo Code"
          className="token--input"
        />
        {result != "" ? (
          <div
            className={
              result === "Valid!" ? "token--valid" : "token--not_valid"
            }
          >
            {result}
          </div>
        ) : (
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              getCouponDetails(input);
            }}
            disabled={input === "" || freeTokens}
            className={
              input === "" || freeTokens
                ? "token--check_button_gray"
                : "token--check_button_green"
            }
          >
            CHECK
          </Button>
        )}
      </div>
      <div className="token--card">
        <div
          className={
            freeTokens
              ? "token--background_text_field disabled"
              : "token--background_text_field"
          }
        />
        <div className={classes.counter}>
          <div>
            <Button
              className={classes.buttonSub}
              onClick={() => {
                if (freeTokens) {
                  setTokens(0);
                  setFreeTokens(0);
                  setInput("");
                  setResult("");
                } else {
                  if (tokens && tokens > 0) {
                    setTokens(tokens - 1);
                  } else {
                    setTokens(0);
                  }
                }
              }}
            >
              <MinusButton />{" "}
            </Button>
          </div>
          <div className={classes.root}>
            <label htmlFor="tokenInput" className="token--token_label">Each Token: $0.50</label>
            <div className={classes.margin}>
              <input
                type="text"
                pattern="[0-9]+"
                value={tokens}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (re.test(e.target.value)) {
                    setTokens(parseInt(e.target.value));
                  }
                  if (e.target.value === "") setTokens(0);
                  return;
                }}
                maxLength={3}
                disabled={freeTokens ? true : false}
                className={
                  freeTokens
                    ? "token-counter_container disabled"
                    : "token-counter_container"
                }
                id="tokenInput"
              />
            </div>
          </div>
          <div>
            <Button
              className={classes.buttonAdd}
              onClick={() => {
                if (tokens) {
                  setTokens(tokens + 1);
                } else {
                  setTokens(1);
                }
              }}
              disabled={freeTokens ? true : false}
            >
              {freeTokens ? <PlusButtonDisabled /> : <PlusButton />}
            </Button>
          </div>
        </div>
        <div className="token--total_container">
          {tokens !== 0 && tokens !== null && (
            <div className="token--subtotal">
              Cost of Tokens : $
              {freeTokens
                ? freeTokens && parseFloat(`${freeTokens * price}`).toFixed(2)
                : tokens && parseFloat(`${tokens * price}`).toFixed(2)}
            </div>
          )}
          {freeTokens !== 0 && freeTokens !== null && (
            <div className="token--token_free">
              {freeTokens} free Tokens : -$
              {freeTokens && parseFloat(`${freeTokens * price}`).toFixed(2)}
            </div>
          )}
          {tokens !== 0 && tokens !== null && (
            <div className="token--amount_duo">
              Amount Due : $
              {freeTokens
                ? 0
                : tokens &&
                  parseFloat(`${(freeTokens + tokens) * price}`).toFixed(2)}
            </div>
          )}
        </div>
      </div>
      <div className="token--strip_button_container">
        {tokens === null || tokens == 0 ? (
          " "
        ) : (
          <span>
            {freeTokens ? (
              <button
                className="token--checkout-button"
                onClick={async (e) => {
                  e.stopPropagation();
                  await useToken(input);
                }}
              >
                Use Promo Code
              </button>
            ) : (
              <Checkout
                name="TeammateMe Store"
                description="Tokens used to purchase more teams."
                amount={(tokens * price).toFixed(2)}
                tokens
              />
            )}
          </span>
        )}
      </div>
      <div className="token--new_balance">
        New Balance: {parseFloat(tokens + navTeamCredits).toFixed(0)} Tokens
      </div>
      <button onClick={toggleTokenMenu} className="token--up_button">
        <UpIcon />
      </button>
    </div>
  );
}
